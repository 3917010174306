import { Component } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import { getModule} from 'vuex-module-decorators';
import axios, {AxiosPromise} from 'axios';
import {CONFIG_ENV, URL_WORKERS} from '@/config';
import user from '@/store/user';
import basket from '@/store/basket';
import eventbus from '@/store/eventbus';
import favorites from '@/store/favorites';
import nomenclatoare from '@/store/nomenclatoare';
import mailbox from '@/store/mailbox';
import {TUser} from '@/types/TUser';
import {TMail} from '@/types/TMail';
import MenuApp from './components/MenuApp/MenuApp.vue';
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import {ServiceUser} from '@/services/ServiceUser';
import {getNomenclatoare} from '@/modules/getNomenclatoare'
import {getFavorites} from '@/modules/getFavorites'
import {getBasket} from '@/modules/getBasket'
import { ionLogoFacebook } from '@quasar/extras/ionicons-v5'
import { ionLogoYoutube } from '@quasar/extras/ionicons-v5'
import { ionLogoLinkedin } from '@quasar/extras/ionicons-v5'
import MyButtonLikeClass from "@/components/MyButtonLikeClass/MyButtonLikeClass.vue";
import BrowseCategories from "@/pages/BrowseCategories/BrowseCategories";
import { TCompanyUser } from './types/TCompanyUser';
import { TUserCompany } from './types/TUserCompany';
import { ServiceCompanyUsers } from './services/ServiceCompanyUsers';
import { ServiceAlerts } from './services/ServiceAlerts';
import { TLanguage } from './types/TLanguage';
import { set } from 'vue-gtag';

/*
var MockAdapter = require('axios-mock-adapter');
var mock = new MockAdapter(axios);
mock.onGet('/loadApp').reply(200, {
    userData:{ username:'Florin Codreanu',
        id:'florin.codreanu@bcr.ro',
        empno:12789}
});
*/


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['X-CSRF-TOKEN'] = '1t6bh0lwh72pv.2k9aurnh5tk44';


@Options({
    name: "App",
    components: {MenuApp,ContacteleMeleColorMetal}
})
export default class App extends Vue {
    public leftDrawerOpen = false;
    public rightDrawerOpen = false;
    public visibleMobileBarForSearch =false;
    public loadingResultForFastSearch = false;
    public currentPageTitle= '';
    public inputFastSearch = '';
    public nrRanduriTabel = 10;
    public ionLogoFacebook = ionLogoFacebook;
    public ionLogoYoutube = ionLogoYoutube;
    public ionLogoLinkedin = ionLogoLinkedin;
    public visibleInputFastSearch = false;
    declare public $refs: any;
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public storeBasket = getModule(basket);
    public storeFavorites = getModule(favorites);
    public storeMailbox = getModule(mailbox);
    public storeEventsBus = getModule(eventbus);
    public type='0';
    public token='';
    public languages: Array<TLanguage>=[{id: 'ro', name: 'romana'}, {id: 'en', name: 'english'},{id: 'hu', name: 'magyar'},{id: 'de', name: 'deutsch'}];
    public selectedLang = 'hu';

    public div_el: HTMLDivElement | undefined;
    public iframe: any;
    public mesajSeLoadingNomenclatoare='';
    public filteredCompanies: Array<TUserCompany> = [];


    get nrArticlesInBasket():number{
        return this.storeBasket.basket.length;
    }

    get nrArticlesInFavorites():number{
        return this.storeFavorites.favorites.length;
    }

    get userCompanies(): Array<TUserCompany> {
        return this.userStore.userCompanies;
    }
    

    get showAddBtnBarMenu():boolean{
        let result = false;
        if(this.$route.name=='AdminArticles' || this.$route.name=='AdminCategory' || this.$route.name=='AdminUsers' || this.$route.name=='AdreseLivrare'){
            result=true;
        }
        return result;
    }

    get showSearchBtnBarMenu():boolean{
        let result = false;
        if(this.$route.name=='MyShoppingCart'){
            result=true;
        }
        return result;
    }

    get showBasketBtnBarMenu():boolean{
        return this.$route.name!='MyShoppingCart' && this.$route.name !='AdreseLivrare' && this.$route.name !='AdminArticles' && this.$route.name !='AdminCategory' && this.$route.name !='AdminUsers';
    }

    get user(): TUser {
        return this.userStore.user;
    }

    get isLoadingNomenclatoare(): boolean {
        return this.storeNomenclatoare.isLoadingNomenclatoare;
    }

    get visibleMobileRightBtnForDashboard():boolean{
        return this.userStore.PREVIOUS1_ROUTE_NAME != 'Dashboard' && this.userStore.PREVIOUS2_ROUTE_NAME != 'Dashboard'
            && (this.$route.name=='MyShoppingCart' || this.$route.name=='BrowseArticles');
    }

    get sessionIsValid(): boolean {
        return (this.userStore.userIsAuthenticated && !this.storeNomenclatoare.isLoadingNomenclatoare?true:false);
    }

    public goToDashboardInMobile(){
        this.$router.push({name: 'Dashboard'});
        this.userStore.set_showmenubar(false);
        this.userStore.set_showbackbar(false);
    }
    public goToDashboard(){
        this.$router.push({name: 'Dashboard'});
    }
    public onGoCautaArticole(){
        this.$router.push({name: 'BrowseCategories',  params: { pid: '0' }});
    }

    public onGoToOffers(){
        this.$router.push({name: 'Offers'});
    }

    public setNrRanduriTabel(){
        localStorage.setItem('nrRanduriTabel', this.nrRanduriTabel.toString());
    }

    public clearInputFastSearch(){
        this.inputFastSearch='';
        this.$refs.refOptionsFastSearch.hide();
    }

    public clearListFastSearch(){
        if(!this.inputFastSearch){
            console.log('clearListFastSearch')
        }
    }

    public onGoToBrowseCategories(){
        this.onHideInputFastSearch();
        this.$router.push({name: 'BrowseCategories',  params: { pid: '0', typeOfAccess: 'fromDashboard' }});
        //this.$router.push({name: 'BrowseCategories' });
    }

    public goToFavorite(){
        this.onHideInputFastSearch();
        this.$router.push({name: 'ArticoleFavorite'});
    }

    public onShowInputFastSearch(){
        this.visibleInputFastSearch=true;
    }

    public onHideInputFastSearch(){
        this.inputFastSearch='';
        this.visibleInputFastSearch=false;
        this.visibleMobileBarForSearch=false;
    }

    public get currentRouteName() {
        return this.$route.name;
    }
    get userIsAuthenticated(){
        return this.userStore.userIsAuthenticated;
    }

    get showMenuBar(){
        return this.userStore.showMenuBar;
    }

    get showBackBar(){
        return this.userStore.showBackBar;
    }

    get titleBackBar(){
        return this.userStore.titleBackBar;
    }

    get MyImgProfileString64():string{
        return this.userStore.MyImgProfileString64;
    }

    get pageTransition():string{
        return this.userStore.pageTransition;
    }

    public goToMyProfile(){
        this.$router.push({ name: 'MyProfile' });
    }

    public goBack(){
        this.$router.back();
    }

    public getOptionSArticlesFastSearch(pInputSearch: string){
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vueInst = this;
        if(pInputSearch && pInputSearch.length>1){
            vueInst.$refs.refOptionsFastSearch.show();
        }else{
            vueInst.$refs.refOptionsFastSearch.hide();
        }

        vueInst.loadingResultForFastSearch=false;
    }

    public logout(){
        localStorage.setItem('isAuthenticatedUser', 'n');
        localStorage.setItem('userToken', 'x');
        localStorage.setItem('userid', 'x');
        // localStorage.setItem('lang', this.selectedLang);
        this.userStore.set_user_is_authenticated(false);
        this.$q.notify({
            color: 'purple',
            textColor: 'white',
            position:'top',
            timeout: 1000,
            message: this.$t('message.you_have_been_logout')
        });
        this.userStore.set_user({
            appid: '',
            userid: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNr: '',
            isAdmin: 'n',
            functie: '',
            userToken: '',
            companyName: '',
            cif: '',
            tip: 'client',
            lang: 'en',
            agentId: '',
            id_team: '',
            slid: 0,
            agent_name: ''
        });
        this.$router.push({name: 'Login'});
    }

    public onCloseMenu(){
        this.leftDrawerOpen=false;
    }

    public connectUserMailbox(){
        const vueInst = this;
        if (typeof (Worker) !== "undefined") {
            // Yes! Web worker support!
            const workerMailbox = new Worker(URL_WORKERS+"/workers/mailbox.js");
            workerMailbox.postMessage({cmd: 'getNewMessages'});
            workerMailbox.onmessage = function (event) {
                switch (event.data.cmd) {
                    case 'resultMyNewMessages':
                        // console.log('newMails=%o',event.data.newMails);
                        // ServiceAlerts.getCounters().then( responce => {
                        //     if(responce.status == 'success'){
                        //         vueInst.userStore.set_screencountadrese(responce.countAdrese);
                        //         vueInst.userStore.set_screencountoferte(responce.countOferte);
                        //     }
                        // });
                        ServiceUser.checkSession().then(responce => {
                            if(responce.status == 'error' && vueInst.userStore.userIsAuthenticated) {
                                vueInst.logout();
                                // workerMailbox.terminate();
                            }
                        });
                        // if(event.data.newMails && event.data.newMails.length > 0){
                        //     event.data.newMails.forEach((email:TMail) => {
                        //         vueInst.storeMailbox.push_email(email)
                        //         if(email.refObject=='NewOfferFromSales'){
                        //             vueInst.storeEventsBus.set_event({name:'eventNewOfferFromSales',params:{offerId:email.refAppid}});
                        //         }

                        //     })
                        // }

                        break;
                }
            };
        }
    }

    public checkToken(){
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        console.log('checkToken!!!!!!!!!!!!!!!!!!')
        const vueInst = this;
        const userToken=localStorage.getItem('userToken')||'x';
        const userId=localStorage.getItem('userId')||'x';
        // vueInst.selectedLang = this.$i18n.locale;
        this.selectedLang = this.$i18n.locale;
        console.log("check token " + this.selectedLang);
        // this.changeLanguage(this.selectedLang);
        
        ServiceUser.getCurrentUser(userId,userToken).then(response=>{
            console.log('ServiceUser.getCurrentUser response=%o',response)
            if(response.status=='success') {

                vueInst.connectUserMailbox();
                getFavorites();
                getBasket();
                ServiceAlerts.getCounters().then( responce => {
                    if(responce.status == 'success'){
                        this.userStore.set_screencountadrese(responce.countAdrese);
                        this.userStore.set_screencountoferte(responce.countOferte);
                    }
                });
                vueInst.getUserCompanies(userId);
                

                // this.$q.fullscreen.request().then(()=>{
                //     vueInst.$q.notify({
                //         color: 'teal',
                //         textColor: 'white',
                //         icon: 'positive',
                //         position: 'top',
                //         timeout: 2000,
                //         message: 'FullScreen cu success!'
                //     })
                // }) .catch(err => { console.log('err on Fullscreen=%o',err)
                //
                //     vueInst.$q.notify({
                //         color: 'red',
                //         textColor: 'white',
                //         icon: 'positive',
                //         position: 'top',
                //         timeout: 2000,
                //         message: 'Eroare la FullScrren!'
                //     })
                // });
                localStorage.setItem('isAuthenticatedUser', 'y');
                this.selectedLang = response.user.lang;
                console.log("check token " + this.selectedLang);
                vueInst.changeLanguage(response.user.lang);
                vueInst.userStore.set_user(response.user);
                vueInst.userStore.set_user_is_authenticated(true);
                vueInst.storeNomenclatoare.set_loading_nomenclatoare(true);
                ServiceUser.getProfileImgAsString(vueInst.userStore.user.appid).then((response:any)=>{
                    localStorage.setItem('MyImgProfileString64', response.data);
                    vueInst.userStore.set_myimgprofilestring64(response.data);
                });
                vueInst.storeNomenclatoare.getNomenclatoare().then(response=>{
                    if(response=='success'){
                        vueInst.storeNomenclatoare.set_loading_nomenclatoare(false);
                        vueInst.$router.push({name: 'Dashboard'});
                        vueInst.userStore.set_showmenubar(true);
                        vueInst.userStore.set_showbackbar(false);
                    }
                });
                set({'user-id' : response.user.userid, 'username' : response.user.firstName + ' ' + response.user.lastName});
                
            } else {
                localStorage.setItem('isAuthenticatedUser', 'n');
                localStorage.setItem('userToken', 'x');
                localStorage.setItem('userId', 'x');
                vueInst.userStore.set_user_is_authenticated(false);
            
                vueInst.$router.push({name: 'Login'});
            }
        }).catch(Error=>{
            console.log('Error=%o',Error)
            vueInst.logout();
        });
    }

    public makeMobileBarForSearch(){
        this.visibleMobileBarForSearch=true;
    }

    public onClickAddBtnForDialogChild(){
        this.storeEventsBus.set_event({name:'eventClickAddBtn',params:{emitter:'app'}});
    }

    public getAppUser() {
        const vueInst = this;
        // //vueInst.$q.fullscreen.request(); 
        // this.selectedLang = localStorage.getItem('lang')||'en';
        // this.changeLanguage(this.selectedLang);
        this.selectedLang = this.$i18n.locale;
        console.log("created " + this.selectedLang);
        // this.changeLanguage(this.selectedLang);

        if(location.href.includes("reset_password?token")){
            const arr = (new URL(location.href).searchParams.get('token') as string).split('-');
            localStorage.setItem('type', arr[0]);
            localStorage.setItem('slid', arr[1]);
            localStorage.setItem('token', arr[2]);
            vueInst.userStore.set_user_is_authenticated(false);
            vueInst.$router.push({name: 'ResetPassword'});
        } else {
            localStorage.setItem('type', '0');
            localStorage.setItem('slid', '');
            localStorage.setItem('token', '');
            const isAuthenticatedUser=localStorage.getItem('isAuthenticatedUser')||false;
            if(isAuthenticatedUser){
                vueInst.checkToken();
            }else{
                vueInst.$router.push({name: 'Login'});
            }
        }

    }

    public getUserCompanies(userid: string){
        const vueInst = this;
        ServiceCompanyUsers.getUsersCompanies(userid).then(response=>{
            if(response.status == 'success') {
                // console.log(response.companies);
                vueInst.userStore.set_user_companies(response.companies);
            }
        });
    }

    public changeCompanyUser(val: string){
        const vueInst = this;
        // console.log(val);
        if(val == null || val == ''){
            return;
        }
        ServiceCompanyUsers.changeUserCompany(vueInst.userStore.user.userid, val).then(response=>{
            if(response.status == 'success') {
                // console.log(response.companies);
                vueInst.userStore.user.cif = response.companies[0].cif;
                vueInst.userStore.user.companyName = response.companies[0].denumire;
                vueInst.getAppUser();
            }
        });
    }

    public changeLanguage(val: string) {
        const vueInst = this;
        vueInst.selectedLang = val;
        console.log("change lang " + this.selectedLang);
        localStorage.setItem('lang', vueInst.selectedLang);
        this.$i18n.locale = vueInst.selectedLang;
        ServiceUser.changeLang(vueInst.selectedLang);
        vueInst.storeEventsBus.set_event({name:'changeLanguage', params:null});
    }

    public filterCompanies(val:any, update:any, abort:any) {
        const vueInst=this;
        update(() => {
            if (val === '') {
                vueInst.filteredCompanies = vueInst.userCompanies;
            } else {
                vueInst.filteredCompanies = vueInst.userCompanies.filter(company => company.denumire.toLowerCase().startsWith(val.toLowerCase()));
            }
        })
    }

    public focusOnCompnay(){
        this.$refs.refCompany.focus();
    }

    public putFocusBackToFastSearch(){
        this.$refs.refInputFastSearch.focus();
    }

    public get widthForDrawerDocument(): number{
        let result=0;
        if(this.$q.platform.is.mobile) {result = window.innerWidth;}
        else{
            if(screen.availWidth>1500){ result = 1200;}
            else{
                if(screen.availWidth>1000){ result = 1000;}
                else {
                    if (screen.availWidth > 800) {
                        result = 600;
                    } else {
                        result = window.innerWidth - 100;
                    }
                }
            }
        }
        return result;
    }

    public openCalculatorGreutate(){
        // window.open("https://color-metal.ro/ro/calculator-de-greutate-roman");
    }

    public openLink(link: string){
        // window.open(link);
    }

    public onGoTOMyShoppingCart(){
        this.$router.push({name: 'MyShoppingCart'});
    }

    public closeWindowDocument() {
        this.rightDrawerOpen=false;
    }

    activated(): void {
        this.selectedLang = this.$i18n.locale;
        console.log("activated " + this.selectedLang);
        // this.changeLanguage(this.selectedLang);
    }


    public created(): void {
        const vueInst = this;
        vueInst.mesajSeLoadingNomenclatoare=this.$t('message.loading_nomenclatoare');
        vueInst.userStore.set_screenwidth(screen.availWidth);
        vueInst.userStore.set_screenheight(screen.availHeight);
        getNomenclatoare();
        vueInst.getAppUser();
    }

}
