// index.js
import en from './en.json';
import ro from './ro.json';
import hu from './hu.json';
import de from './de.json';

export const defaultLocale = 'hu'

export const messages = {
  en: en,
  ro: ro,
  hu: hu,
  de: de
}