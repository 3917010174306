// tslint:disable-next-line:only-arrow-functions
export const URL_SITE = ( function() {
    // return 'http://localhost:83/api';
    if ( location.hostname === 'localhost') {
        return 'http://localhost:83/api'
        //return 'https://portal-color.theappsonline.com/api'
        //return 'https:///cmportal.ro';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        return 'https://b1.melindasteel.hu';
    }
})();

export const URL_API = ( function() {
    // return 'http://localhost:83/api';
    if ( location.hostname === 'localhost') {
        return 'http://localhost:83/api'
        //return 'https://portal-color.theappsonline.com/api'
        //return 'https:///cmportal.ro/api';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        return '/api';
    }
})();

export const URL_API_FILE = ( function() {
    // return 'http://localhost:83/api';
    if ( location.hostname === 'localhost') {
        return 'http://localhost:83/api'
        //return 'https://regis.theappsonline.com/api'
        //return 'http://regisdra-test/api';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        //return '/api';
        return 'http://regisdra-files/api';
    }
})();

export const URL_WORKERS = ( function() {
    if ( location.hostname === 'localhost') {
        return ''
        //return 'https://portal-color.theappsonline.com/api'
        //return 'https:///cmportal.ro/api';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        return '';
    }
})();
// tslint:disable-next-line:only-arrow-functions

export const CONFIG_ENV = {
    URL_USER: {
        getCurrentUser : URL_API + '/user/get_current_user',
        loginUser : URL_API + '/user/login',
        saveMyProfile : URL_API+ "/user/save_my_profile",
        getMyImageProfileAsString: URL_API + '/user/my_image_profile_as_string',
        getMyImageProfile : URL_API+ "/user/get_my_image_profile",
        uploadImageMyProfile : URL_API+ "/user/upload_image_my_profile",
        getMyColorMetalUserContacts : URL_API+ "/user/color_contacts",
        changePassword : URL_API + '/user/change_password',
        changeLanguage : URL_API + '/user/change_lang',
        check_session_status: URL_API + '/user/check_session_status'
        
    },
    URL_ADMIN: {
        users : URL_API + '/admin/users',
        userDetails: URL_API + '/admin/users/user_details',
        resetPassword : URL_API + '/admin/users/reset_password',
        saveNewUser : URL_API + '/admin/users/new_user',
        getCompanyByAppid: URL_API + '/admin/companies/company_by_appid',
        getCompanyByCif: URL_API + '/admin/companies/company_by_cif',
        getCompanyAddress: URL_API + '/admin/companies/get_company_address',
        getCompaniesByFilter: URL_API + '/admin/companies/filter',
        getOnePageCompanies: URL_API + '/admin/companies/page',
        nomCategory: URL_API + '/admin/category',
        nomProduct: URL_API + '/admin/product',
        imageCategoryAsString: URL_API + '/admin/category/image_as_string',
        treeDataCategories: URL_API + '/admin/category/treedata',
        listDataCategories: URL_API + '/admin/category/listdata',
        saveNewCompany: URL_API + '/admin/companies/new_company',
        company_status: URL_API + '/admin/companies/change_status_activ_inactiv',
        user_status: URL_API + '/admin/users/change_status',
        agents: URL_API + '/admin/users/agents',
        delete_user: URL_API + '/admin/users/delete_user',
        users_back_office: URL_API + '/admin/users/users_back_office',
        get_articles_apollo: URL_API + '/admin/product/get_articles_apollo',
        getCompaniesApollo: URL_API + '/admin/companies/get_companies_apollo',
        userDetails2: URL_API + '/admin/users/user_details2',
        editUser : URL_API + '/admin/users/edit_user',
        getCompanyApollo: URL_API + '/admin/companies/get_company_apollo',
        get_article_apollo: URL_API + '/admin/product/get_article_apollo',
        changeOrderCategory: URL_API + '/admin/category/change_order_category'
    },

    URL_USER_ADRESE_LIVRARE: {
        adresa : URL_API + '/user/adrese_livrare',
        adrese : URL_API + '/user/adrese_livrare',
        getAdresa: URL_API + '/user/get_adresa',
        getAdresaByCif: URL_API + '/user/adrese_livrare_by_cif',
        new_adresa: URL_API + '/user/delete_new_address',
        adreseApollo: URL_API + '/admin/companies/adrese_apollo'
    },

    URL_COMPANY_USERS: {
        allUsers : URL_API + '/user/company_users',
        uploadOfertaPDF: URL_API + '/admin/companies/upload_oferta_pdf'
    },

    URL_ARTICOL:{
       articol: URL_API + '/articol',
       browseArticles: URL_API + '/browse/articles',
       identifyArticleInDB: URL_API + '/browse/identify_article',
       identifyArticleInDBAndPutIntoFavorites : URL_API + '/favorites/identify_article_and_put_in_favorites',
       putArticlebyCodeIntoFavorites : URL_API + '/favorites/put_article_by_code_in_favorite',
       identifyArticleInDBAndPutIntoBasket : URL_API + '/basket/identify_article_and_put_in_basket',
       putArticlebyCodeIntoBasket : URL_API + '/basket/put_article_by_code_in_basket',
       favorites: URL_API + '/favorites',
       basket: URL_API + '/basket',
       calculDimensiuniDebitare: URL_API + '/calculator',
       articolByProductCode: URL_API + '/articol/product_code',
       get_article_stock: URL_API + '/get_article_stock',
       search_articles: URL_API + '/browse/search_articles',
       get_article_price: URL_API + '/get_article_price',
       get_articles_category: URL_API + '/browse/articles/get_articles_category',
       identifyArticleInDBAndPutIntoBasketNoDimensions: URL_API + '/basket/identify_article_and_put_in_basket_no_dimensions',
       articolByProductCode2: URL_API + '/articol/product_code2'
    },

    URL_CATEGORY:{
        browseCategories: URL_API + '/browse/categories',
        getJPG: URL_API + '/browse/category/image',
    },

    URL_OFFER:{
        sendBasketForAnOffer: URL_API + '/offer/send_basket_for_an_offer',
        sendCerereForNewOffer: URL_API + '/offer/send_cerere_for_new_offer',
        getMyOffers: URL_API + '/offer/my_offers',
        OneOffer: URL_API + '/offer/offer',
        acceptOffer: URL_API + '/offer/accept_offer',
        sendCerereForNewOffer2: URL_API + '/offer/send_cerere_for_new_offer_2',
        resend_cerere: URL_API + '/offer/resend_cerere'
    },

    URL_CERERE:{
        cerere: URL_API + '/cerere',
        cereri: URL_API + '/cerere/cereri',
        update_cerere_status: URL_API + '/cerere/update_cerere_status',
        increase_modified_number: URL_API + '/cerere/increase_modified_number'
    },

    URL_INVOICE:{
        getMyInvoices: URL_API + '/invoice',
        download_invoice: URL_API + '/invoice',
        getInvoicesFromNAV: URL_API + '/invoice/invoiceNAV',
        getInvoice: URL_API + '/invoice/single_invoice',
        download_certificate: URL_API + '/invoice/certificate',
        getFilteredInvoices: URL_API + '/invoice/filtered_invoices'
    },

    URL_BALANTA:{
        getMyAccountingBalanceJson: URL_API + '/my_accounting_balance/json',
        getPdf: URL_API + '/my_accounting_balance/pdf',
        getPdfMobile: URL_API + '/my_accounting_balance/pdf_mobile'
    },

    URL_RESET_PASSWORD: {
        checkToken: URL_API + "/reset_password/check_token"
    },

    URL_ALERTS: {
        getMyAlerts: URL_API + "/alerts/get_alerts",
        sendNotidication: URL_API + "/alerts/send_notification",
        sendNotidicationEmail: URL_API + "/alerts/send_notification_email",
        sendNotidicationEmailBK: URL_API + "/alerts/send_notification_email_bk"
    },

    URL_COUNTER: {
        getCounters: URL_API + "/count_new_data"
    },

    URL_DOWNLOAD: {
        generare_oferta: URL_API + '/offer/generte_offer',
        generare_oferta_pdf_link: URL_API + '/offer/generare_oferta_pdf_link',
        generare_oferta_pdf_link2: URL_API + '/offer/generare_oferta_pdf_link2',
        generare_pdf_privacy: URL_API + '/generare_pdf_privacy',
        generare_excel_request: URL_API + '/generare_excel_request',
        delete_file: URL_API + '/delete_file',
        exportExcellUsers: URL_API + '/export_excell_users',
        check_offer_on_system: URL_API + '/check_offer_on_system'
    },

    URL_MAIL: {
        send_mail: URL_API + '/send_mail',
        send_mail_new_request: URL_API + '/send_mail_new_request',
        send_mail_new_offer: URL_API + '/send_mail_new_offer',
        send_mail_contact: URL_API + '/send_mail_contact',
        send_mail_new_user: URL_API + '/send_mail_new_user',
        send_mail_accept_offer: URL_API + '/send_mail_accept_offer',
        send_mail_refuse_offer: URL_API + '/send_mail_refuse_offer',
        resend_mail_modified_offer: URL_API + '/resend_mail_modified_offer'
    },

    URL_USER_COMPANIES: {
        getUserCompanies: URL_API + '/companies',
        changeUserCompany: URL_API + '/companies/change_company_user'
    },

    URL_LOGS: {
        updateTimeSpentPerPage: URL_API + '/log/update_time_spent_per_page',
        getUsersNumberLogsPerAgent: URL_API + '/report/numbers_login',
        getOffersDetailsReport: URL_API + '/report/offers',
        getUsersTimeSpent: URL_API + '/report/user_time_spent',
        getBasketReport: URL_API + '/report/basket_report',
        exportExcell: URL_API + '/report/export_excel',
        getCategoriesDetailsReport: URL_API + '/report/categories',
        getUsersActiveInactiveLastMonth: URL_API + '/report/users_active_inactive_last_month',
        getOffersDetailsReportCount: URL_API + '/report/offers_count',
        getOffersDetailsReportQuantity: URL_API + '/report/offers_quantity',
        getOffersDetailsReportTimeBetween: URL_API +'/report/offers_time',
        getCategoriesDetailsReportQuantity: URL_API + '/report/categories_quantity',
        getCategoriesDetailsReportClicks: URL_API + '/report/categories_clicks'
    },

    myLocale: {
        /* starting with Sunday */
        days: 'Duminica_Luni_Marti_Miercuri_Joi_Vineri_Sambata'.split('_'),
        daysShort: 'Dum_Lun_Mar_Mie_Joi_Vie_Sam'.split('_'),
        months: 'Ianuarie_Februarie_Martie_Aprilie_Mai_Iunie_Iulie_August_Septembrie_Octombrie_Noiembrie_Decembrie'.split('_'),
        monthsShort: 'Ian_Feb_Mar_Apr_Mai_Iun_Iul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        firstDayOfWeek: 1
    },
    myLocaleEnglish: {
        /* starting with Sunday */
        days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
        daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        firstDayOfWeek: 1 // Assuming Sunday is the first day of the week in English
    },
    myLocaleHungarian: {
        /* starting with Sunday */
        days: 'vasárnap_hétfő_kedd_szerda_csütörtök_péntek_szombat'.split('_'),
        daysShort: 'vas_hét_kedd_sze_csüt_pén_szo'.split('_'),
        months: 'január_február_március_április_május_június_július_augusztus_szeptember_október_november_december'.split('_'),
        monthsShort: 'jan_feb_márc_ápr_máj_jún_júl_aug_szept_okt_nov_dec'.split('_'),
        firstDayOfWeek: 1 // Assuming Monday is the first day of the week in Hungarian
    },
    myLocaleGerman: {
        /* starting with Sunday */
        days: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
        daysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
        months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
        monthsShort: 'Jan_Feb_Mär_Apr_Mai_Jun_Jul_Aug_Sep_Okt_Nov_Dez'.split('_'),
        firstDayOfWeek: 1 // Assuming Monday as the first day of the week in German
    }
}

export const ArticolNull={
    appid:'',
    pid:'',
    categoryPid:'',
    lantHierarchyCategories: '',
    code: '',
    name:'',
    UMBase:'',
    isActive:false,
    withLength: false,
    withWidth: false,
    withThickness: false,
    withDiameter: false,
    sizeLength:null,
    sizeWidth:null,
    sizeThickness:null,
    sizeDiameter:null
}

export const ArticolNullInBasket = {
    appid:'',
    productPid:'',
    categoryPid: '',
    productCode:'',
    productName: '',
    categoryName: '',
    qBuc: 0,
    q_um_base: 0,
    qUm1 :null,
    qUm2: null,
    um1: null,
    um2: null,
    l: null,
    w:null,
    t:null,
    d:null,
    h:null,
    a:null,
    k:null,
    g:null,
    dorescDebitare:false,
    cuttingLength:null,
    cuttingWidth:null
}